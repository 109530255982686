<template>
    <basic-layout>
        <app-bar :title="title"/>
        <v-container class="fill-height pa-5" fluid>
            <v-row align="center" justify="center">
                <v-col cols="12" sm="8" md="4" >
                    <p>This Application is maintained and managed by MinSCAT Management Information System Office.</p>
                </v-col>
            </v-row>
        </v-container>
    </basic-layout>
</template>

<script>
    export default {
        name: "About",
        data() {
            return {
                title: "About",
            }
        }
    }
</script>

<style scoped>

</style>